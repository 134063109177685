
import React from "react"
import {
  Form,
  FormGroup,
  Input,
  CardTitle,
} from 'reactstrap'
import { motion } from "framer-motion"
import { BlockMath } from 'react-katex';
import 'katex/dist/katex.min'

const Algebrite = require('algebrite')



export default class Deriv extends React.Component {
  constructor() {
    super()
    this.state = { email: '\\sin(x)^{69}\\cos(x)^{420} ', latex: ' ', result: null }
  }
  _handleSubmit = async e => {
    e.preventDefault()
    const idk = this.state.email.toString()
    const vru = idk.replace(/[&\/\\#,$~%'":*?<>]/g, '')
    const vruu = vru.replace(/[{]/g, '(')
    const vruuu = vruu.replace(/[}]/g, ')')
    const hmm  =  Algebrite.derivative(Algebrite.eval(vruuu)).toString()
    const hmm2  =  Algebrite.simplify(Algebrite.eval(hmm)).toString()
    const clean = hmm2.replace(/[*]/g, '' )
    const cleanr = clean.replace(/[ª]/g, ' ' )
    const cleanerrr = cleanr.replaceAll('^', '\\uparrow' )
    const cleanerrr2 = cleanerrr.replaceAll('sin', '\\sin' )
    const cleanerrr3 = cleanerrr2.replaceAll('cos', '\\cos' )
  this.setState( {latex:`  ${cleanerrr3 } ` })
  this.setState( {message: "El resultado es :" })
    
  }
  
handleChange = event => {
    this.setState({ email:event.target.value })
  }
  
render() {  
    const result = (this.state.email).toString()
  return (
      
    <>
    
    <CardTitle className="text-center  mb-3">
   <a><h1> Derivadas </h1></a>
  </CardTitle>
 
 
  <br />
      <Form className="text-center" onSubmit={this._handleSubmit}>
        <FormGroup>
        <Input
         
          label="Email"
          autoComplete="email"
          variant="outlined"
          value={this.state.email}
          onChange={this.handleChange}
        />
        <br />
        <BlockMath math= { `\\mathrm{d_x}( ${result}) `}
        errorColor={'#cc0000'}
        renderError={(error) => {
            return <b>Error: That's not math bruh</b>
          }}
        
        />
 <br />
          <br />
        <motion.div
      
      whileHover={{ scale: 1.1,  }}
      whileTap={{ scale: 0.9,  }}
   
  >
       < button  className="btn btn-outline-success text-uppercase">
         Calcular
          </button>
          </motion.div>
          <br />
          <br />
          < h1> {this.state.message} </h1>
          <BlockMath math= { ` ${this.state.latex} `}
          
          />
    
        </FormGroup>
      </Form>
      </>
    )
  }
}