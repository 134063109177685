import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { GlobalStyle } from '../util/styles'
import {Im} from '../components/PageTitle/styles'
import galaxy from '../images/galaxy.png'
import {PPost} from '../components/PostExcerpt/styles'
import 'katex/dist/katex.min.css'
import Integral from '../components/calc'
import Deriv from '../components/deriv'
import Matx from '../components/matrix'
import Eig from '../components/eigen'
import {Ptd, Ptd2} from '../components/PageTitle/styles'

const AboutPage = () => (
  <>
   <Im src={galaxy}  />
   <Ptd2 >
          </Ptd2>
  <Layout pageTitle="Boring Math">
    <GlobalStyle></GlobalStyle>
    <PPost style={{ minHeight: '100%'}}>
    <SEO title="Boring Math" keywords={[`Calculus`, `Matrix Calculator`, `Inverse of a matrix`]} />
  <div class="matrix">

    <Matx/>
    </div>
    <div class="matrix">
<Eig/>
</div>
    <div class="matrix">
      <Deriv />
  </div>
    <div class="matrix">
  <Integral/>
  </div>
    </PPost>
  </Layout>
  </>
  

)



export default AboutPage