
import React from "react"
import {
  Form,
  FormGroup,
  Input,
  CardTitle,
} from 'reactstrap'
import { motion } from "framer-motion"
import { BlockMath } from 'react-katex';
import 'katex/dist/katex.min'

const Algebrite = require('algebrite')



export default class Integral extends React.Component {
  constructor() {
    super()
    this.state = { email: 120, phi: 21, psi: ' ' ,latex: ' ',  email2: 8, latex2: ' ', email3: ' ', latex3:' ', latexpsi: ' ', result: null }
}
  _handleSubmit = async e => {
    e.preventDefault()
    const idk = parseFloat(this.state.email)
   
    const hmm  =  23.45*Math.sin((360*(idk-80)/365)* (Math.PI / 180))
    const idk2 = this.state.email2
    const hour = parseFloat(15*(12-idk2))
    const hour2 = hour.toPrecision(4).toString()
    const alpha = this.state.email3.toString()
    const phi = this.state.phi.toString()
    const phiv = this.state.phi
    const deltasolar  = hmm.toPrecision(4).toString()  
    const alpha2 = Math.asin(Math.sin(hmm*(Math.PI / 180))*Math.sin(phiv* (Math.PI / 180))+Math.cos(hmm*(Math.PI / 180))*Math.cos(phiv* (Math.PI / 180))*Math.cos(hour* (Math.PI / 180)))*180/Math.PI
    let alpha3= 0
    let psi2 = 0
    if(alpha2<0){
      alpha3 = 0
    }
    else alpha3= alpha2.toPrecision(4).toString()

    if(idk2<=12){
     psi2 = Math.acos((Math.sin(alpha2*Math.PI/180)*Math.sin(phiv*Math.PI / 180)-Math.sin(hmm*(Math.PI / 180)))/(Math.cos(alpha2*Math.PI / 180)*Math.cos(phiv*Math.PI / 180)))*180/Math.PI
    } 
    else psi2 = -1*(Math.acos((Math.sin(alpha2*Math.PI/180)*Math.sin(phiv*Math.PI / 180)-Math.sin(hmm*(Math.PI / 180)))/(Math.cos(alpha2*Math.PI / 180)*Math.cos(phiv*Math.PI / 180)))*180/Math.PI)
    const psi3 = psi2.toPrecision(4).toString()
  this.setState( {latex:`  ${deltasolar}\\degree ` })
  this.setState( {latex2:`  ${hour2}\\degree` })
  this.setState( {latex3:` ${alpha3}\\degree`})
  this.setState( {latexpsi:` ${psi3}\\degree`})
  this.setState( {message: "El resultado es :" })
    
  }
  
handleChange = event => {
    this.setState({ email:event.target.value })
  }
  handleChange2 = event => {
    this.setState({ email2:event.target.value })
  } 
  handleChangephi = event => {
    this.setState({ phi:event.target.value })
  } 
   handleChangepsi = event => {
    this.setState({ psi:event.target.value })
  }
render() {  
    const result = (this.state.email).toString()
  return (
      
    <>
    
    <CardTitle className="text-center  mb-3">
   <a><h1> Calculadora solar </h1></a>
  </CardTitle>
 
 
  <br />
      <Form className="text-center" onSubmit={this._handleSubmit}>
        <FormGroup>
        <Input
         
          label="Email"
          autoComplete="email"
          variant="outlined"
          value={this.state.email}
          onChange={this.handleChange}
        />
         <Input
         
         label="Email"
         autoComplete="email"
         variant="outlined"
         value={this.state.email2}
         onChange={this.handleChange2}
       />
       <Input
         
         label="Email"
         autoComplete="email"
         variant="outlined"
         value={this.state.phi}
         onChange={this.handleChangephi}
       />
 
        <br />
        <BlockMath math= { `\\delta=23.45\\sin(\\frac{360(${this.state.email} -80)}{365})`}
        errorColor={'#cc0000'}
        renderError={(error) => {
            return <b>Error: That's not math bruh</b>
          }}
        
        />
 <br />
 <br />
        <BlockMath math= { `\\omega=15\\times(12-${this.state.email2})`}
        errorColor={'#cc0000'}
        renderError={(error) => {
            return <b>Error: That's not math bruh</b>
          }}
        
        />
 <br />
 <BlockMath math= {`\\phi = ${this.state.phi}`} 
          
          />
          <br />
        <motion.div
      
      whileHover={{ scale: 1.1,  }}
      whileTap={{ scale: 0.9,  }}
   
  >
       < button  className="btn btn-outline-success text-uppercase">
         Calcular
          </button>
          </motion.div>
          <br />
          <br />
          < h1> {this.state.message} </h1>
          <BlockMath math={`\\delta = ${this.state.latex} `}
          
          />
            <BlockMath math ={`\\omega = ${this.state.latex2} `}
          
          
          />
           <BlockMath math={` \\alpha = ${this.state.latex3} `}
          
          
          />
           
           <BlockMath math={` \\psi = ${this.state.latexpsi} `}
          
          
          />
        </FormGroup>
      </Form>
      </>
    )
  }
}
