
import React from "react"
import {
  Form,
  FormGroup,
  Input,
  CardTitle,
} from 'reactstrap'
import { motion } from "framer-motion"
import { BlockMath } from 'react-katex';
import 'katex/dist/katex.min'

const Algebrite = require('algebrite')



export default class Eig extends React.Component {
  constructor() {
    super()
    this.state = { email: '4 & 69 & 69 \\\\ 69 & 42 & 69 \\\\ 69 & 69 & 420', eigen: ' '  }
  }
  _handleSubmit = async e => {
    e.preventDefault()
    const idk = this.state.email.toString()
    const mathp1 = idk.replace('\\left[\\begin{array}{cc}', '')
    const mathp21 =mathp1.replace('\\left[\\begin{array}{ccc}', '')
    const mathp31 = mathp21.replace('\\left[\\begin{array}{cccc}', '')
    const mathp41 = mathp31.replace('\\left[\\begin{array}{ccccc}', '')
    const mathp51 = mathp41.replace('\\left[\\begin{array}{cccccc}', '')
    const mathp61 = mathp51.replace('\\left[\\begin{array}{ccccccc}', '')
    const mathp71 = mathp61.replace('\\left[\\begin{array}{cccccccc}', '')
    const mathpp81 = mathp71.replace('\\end{array}\\right]', '')
    const v = mathpp81.replace(/[#$~%'"\s:?<>]/g, '')
    const vruse12 = v.replaceAll('\\underline', '')
    const vruse = vruse12.replaceAll('\\frac', '')
    const vrusexs = vruse.replaceAll('}{', '/')
    const vrusexs2 = vrusexs.replaceAll('}', '')
    const vrusexs3 = vrusexs2.replaceAll('{', '')
    const z = vrusexs3.replace(/[&]/g, ',')
    const x = z.replaceAll('\\\\', '¿')
    const vru = x.replaceAll('¿', '],[')
    const vruu = vru.replace(/[{]/g, '(')
    const vruuu = vruu.replace(/[}]/g, ')')
    const eig = Algebrite.eigenval(` [[ ${vruuu} ]]`).toString()
    const clean2 = eig.replaceAll(/[*]/g, ' \\timesª' )
    const cleanr2 = clean2.replace(/[ª]/g, ' ' )
    const cleaner2 = cleanr2.replace(/[(]/g, '{' )
    const cleanerr2 = cleaner2.replace(/[)]/g, '}' )
    const cleanerrr2 = cleanerr2.replace('pi', '\\pi' )
    const a2 = cleanerrr2.replaceAll('],[', '}}{}\\\\\\\\ \\frac{{ ')
    const b2 = a2.replaceAll(',', ' }}{}&\\frac{{')
    const c2 = b2.replaceAll('[[', '\\frac{{')
    const d2 = c2.replaceAll(']]', '}}')
    const df2 = d2.replaceAll('1/', '1}}{{')
    const ef2 = df2.replaceAll('2/', '2}}{{')
    const ff2 = ef2.replaceAll('3/', '3}}{{')
    const gf2 = ff2.replaceAll('4/', '4}}{{')
    const hf2 = gf2.replaceAll('5/', '5}}{{')
    const iif2= hf2.replaceAll('6/', '6}}{{')
    const jf2 = iif2.replaceAll('7/', '7}}{{')
    const kf2 = jf2.replaceAll('8/', '8}}{{')
    const lf2 = kf2.replaceAll('9/', '9}}{{ ')
    const mf2 = lf2.replaceAll('0/', '0}}{{')


  this.setState( {eigen:` ${mf2} ` })
  this.setState( {message: "El resultado es :" })
  }

handleChange = event => {
    this.setState({ email:event.target.value })
  }
  
render() {  
    const result = this.state.email.toString()
    const hp = result.replace('\\left[\\begin{array}{cc}', '')
    const hp2 =hp.replace('\\left[\\begin{array}{ccc}', '')
    const hp3 = hp2.replace('\\left[\\begin{array}{cccc}', '')
    const hp4 = hp3.replace('\\left[\\begin{array}{ccccc}', '')
    const hp5 = hp4.replace('\\left[\\begin{array}{cccccc}', '')
    const hp6 = hp5.replace('\\left[\\begin{array}{ccccccc}', '')
    const hp7 = hp6.replace('\\left[\\begin{array}{cccccccc}', '')
    const hpp83 = hp7.replace('\\end{array}\\right]', '')

  return (
      
    <>
    
    <CardTitle className="text-center  mb-3">
   <a><h1>Eigen Stuff</h1></a>
  </CardTitle>
 
 
  <br />
      <Form className="text-center" onSubmit={this._handleSubmit}>
        <FormGroup>
        <Input
         
          label="Email"
          autoComplete="email"
          variant="outlined"
          value={this.state.email}
          onChange={this.handleChange} 
        />
        <br />
        <BlockMath math= { `\\mathrm{A} = \\left[\\begin{array}{lll} ${hpp83} \\end{array}\\right] ` }
        errorColor={'#cc0000'}
        renderError={(error) => {
            return <b>Error: That's not math bruh</b>
          }}
        
        />

        <motion.div
      
      whileHover={{ scale: 1.1,  }}
      whileTap={{ scale: 0.9,  }}
   
  >
      <br />
          <br />
       < button  className="btn btn-outline-success text-uppercase">
         Calcular
          </button>
          </motion.div>
          <br />

          < h1> {this.state.message} </h1>
          <br />

<b>Solo para matrices simétricas
</b> 
<BlockMath math= { ` \\downarrow`}

/>

<BlockMath math= { ` \\mathrm{Eigen Values} = \\left[\\begin{array}{lll} ${this.state.eigen} {}\\end{array}\\right] `}

/>

      


    
        </FormGroup>
      </Form>
      </>
    )
  }
}