
import React from "react"
import {
  Form,
  FormGroup,
  Input,
  CardTitle,
} from 'reactstrap'
import { motion } from "framer-motion"
import { BlockMath } from 'react-katex';
import 'katex/dist/katex.min'

const Algebrite = require('algebrite')



export default class Matx extends React.Component {
  constructor() {
    super()
    this.state = { email: '4 & 42 & 420 \\\\ 69 & 420 & 69 \\\\ 690 & 6900 & 420', inv: ' ', det: ' ', adj: ' ', }
  }
  _handleSubmit = async e => {
    e.preventDefault()
    const idk = this.state.email.toString()
    const mathp1 = idk.replaceAll('\\left[\\begin{array}{cc}', '')
    const mathp21 =mathp1.replaceAll('\\left[\\begin{array}{ccc}', '')
    const mathp31 = mathp21.replaceAll('\\left[\\begin{array}{cccc}', '')
    const mathp41 = mathp31.replaceAll('\\left[\\begin{array}{ccccc}', '')
    const mathp51 = mathp41.replaceAll('\\left[\\begin{array}{cccccc}', '')
    const mathp61 = mathp51.replaceAll('\\left[\\begin{array}{ccccccc}', '')
    const mathp71 = mathp61.replaceAll('\\left[\\begin{array}{cccccccc}', '')
    const mathpp81 = mathp71.replaceAll('\\end{array}\\right]', '')
    const mathpp812 = mathpp81.replaceAll('\\begin{equation}', '')
    const mathpp8122 = mathpp812.replaceAll('\\end{equation}', '')
    const v = mathpp8122.replace(/[#$~%'"\s:?<>]/g, '')
    const vruse12 = v.replaceAll('\\underline', '')
    const vruse = vruse12.replaceAll('\\frac', '')
    const vrusexs = vruse.replaceAll('}{', '/')
    const vrusexs2 = vrusexs.replaceAll('}', '')
    const vrusexs3 = vrusexs2.replaceAll('{', '')
    const z = vrusexs3.replace(/[&]/g, ',')
    const x = z.replaceAll('\\\\', '¿')
    const vru = x.replaceAll('¿', '],[')
    const vruu = vru.replace(/[{]/g, '(')
    const vruuu = vruu.replace(/[}]/g, ')')
    const vruuu2 = vruuu.replaceAll('/,', ',')
    const vruuu3 = vruuu2.replaceAll('[],', '')
    const vruuu4 = vruuu3.replaceAll(/[}]/g, ')')
    const vruuu5 = vruuu4.replace(/[\s]/g, '')
    const vruuu6 = vruuu5.replaceAll('/]', ']')
    const hmm  = `[[${vruuu6}]]`
    const vruuu7 = hmm.replaceAll('/]', ']')
    const hmm2  =  Algebrite.inv(`${vruuu7}`).toString()
    const det  =  Algebrite.det(`${vruuu7}`)
    const adj  =  Algebrite.adj(`${vruuu7}`).toString()
    const clean = hmm2.replaceAll(/[*]/g, ' \\timesª' )
    const cleanr = clean.replace(/[ª]/g, ' ' )
    const cleaner = cleanr.replace(/[(]/g, '{' )
    const cleanerr = cleaner.replace(/[)]/g, '}' )
    const cleanerrr = cleanerr.replace('pi', '\\pi' )
    const a = cleanerrr.replaceAll('],[', '}}{}\\\\\\\\ \\frac{{ ')
    const b = a.replaceAll(',', ' }}{}&\\frac{{')
    const c = b.replaceAll('[[', '\\frac{{')
    const d = c.replaceAll(']]', '}}')
    const df = d.replaceAll('1/', '1}}{{')
    const ef = df.replaceAll('2/', '2}}{{')
    const ff = ef.replaceAll('3/', '3}}{{')
    const gf = ff.replaceAll('4/', '4}}{{')
    const hf = gf.replaceAll('5/', '5}}{{')
    const iif = hf.replaceAll('6/', '6}}{{')
    const jf = iif.replaceAll('7/', '7}}{{')
    const kf = jf.replaceAll('8/', '8}}{{')
    const lf = kf.replaceAll('9/', '9}}{{ ')
    const mf = lf.replaceAll('0/', '0}}{{')
    const mw = mf.replaceAll('a/', 'a}}{{')
    const nw = mw.replaceAll('b/', 'b}}{{')
    const ñw = nw.replaceAll('c/', 'c}}{{')
    const ow = ñw.replaceAll('d/', 'd}}{{')
    const pw = ow.replaceAll('e/', 'e}}{{')
    const qw = pw.replaceAll('f/', 'f}}{{')
    const rw = qw.replaceAll('g/', 'g}}{{')
    const sw = rw.replaceAll('h/', 'h}}{{')
    const tw = sw.replaceAll('i/', 'i}}{{')
    const aw = tw.replaceAll('j/', 'j}}{{')
    const bw = aw.replaceAll('k/', 'k}}{{')
    const cw = bw.replaceAll('l/', 'l}}{{')
    const dw = cw.replaceAll('m/', 'm}}{{')
    const ew = dw.replaceAll('o/', 'o}}{{')
    const fw = ew.replaceAll('p/', 'p}}{{')
    const gw = fw.replaceAll('q/', 'q}}{{')
    const hw = gw.replaceAll('r/', 'r}}{{')
    const iw = hw.replaceAll('b^2', 'b^2}}{{')
    const jw = iw.replaceAll('', '')
    const kw = jw.replaceAll('w/', 'w}}{{')
    const lw = kw.replaceAll('x/', 'x}}{{')
    const xw = lw.replaceAll('y/', 'y}}{{')
    const zw = xw.replaceAll('z/', 'z}}{{')

    const cleanad = adj.replaceAll(/[*]/g, ' \\timesª' )
    const cleanrad = cleanad.replace(/[ª]/g, ' ' )
    const cleanerad = cleanrad.replace(/[(]/g, '{' )
    const cleanerrad = cleanerad.replace(/[)]/g, '}' )
    const cleanerrrad = cleanerrad.replace('pi', '\\pi' )
    const aad = cleanerrrad.replaceAll('],[', '}}{}\\\\\\\\ \\frac{{ ')
    const bad = aad.replaceAll(',', ' }}{}&\\frac{{')
    const cad = bad.replaceAll('[[', '\\frac{{')
    const dad = cad.replaceAll(']]', '}}')
    const dfad = dad.replaceAll('1/', '1}}{{')
    const efad = dfad.replaceAll('2/', '2}}{{')
    const ffad = efad.replaceAll('3/', '3}}{{')
    const gfad = ffad.replaceAll('4/', '4}}{{')
    const hfad = gfad.replaceAll('5/', '5}}{{')
    const iifad = hfad.replaceAll('6/', '6}}{{')
    const jfad = iifad.replaceAll('7/', '7}}{{')
    const kfad= jfad.replaceAll('8/', '8}}{{')
    const lfad = kfad.replaceAll('9/', '9}}{{ ')
    const mfad = lfad.replaceAll('0/', '0}}{{')
    const mwad = mfad.replaceAll('a/', 'a}}{{')
    const nwad = mwad.replaceAll('b/', 'b}}{{')
    const ñwad = nwad.replaceAll('c/', 'c}}{{')
    const owad = ñwad.replaceAll('d/', 'd}}{{')
    const pwad = owad.replaceAll('e/', 'e}}{{')
    const qwad = pwad.replaceAll('f/', 'f}}{{')
    const rwad = qwad.replaceAll('g/', 'g}}{{')
    const swad = rwad.replaceAll('h/', 'h}}{{')
    const twad = swad.replaceAll('i/', 'i}}{{')
    const awad = twad.replaceAll('j/', 'j}}{{')
    const bwad = awad.replaceAll('k/', 'k}}{{')
    const cwad = bwad.replaceAll('l/', 'l}}{{')
    const dwad = cwad.replaceAll('m/', 'm}}{{')
    const ewad = dwad.replaceAll('o/', 'o}}{{')
    const fwad = ewad.replaceAll('p/', 'p}}{{')
    const gwad = fwad.replaceAll('q/', 'q}}{{')
    const hwad = gwad.replaceAll('r/', 'r}}{{')
    const iwad = hwad.replaceAll('b^2', 'b^2}}{{')
    const jwad = iwad.replaceAll('', '')
    const kwad = jwad.replaceAll('w/', 'w}}{{')
    const lwad = kwad.replaceAll('x/', 'x}}{{')
    const xwad = lwad.replaceAll('y/', 'y}}{{')
    const zwad = xwad.replaceAll('z/', 'z}}{{')


















    
    
  this.setState( {inv:` ${zw} ` })
  this.setState( {det:` ${det} ` })
  this.setState( {adj:` ${zwad} ` })
  this.setState( {message: "El resultado es :" })
  }

handleChange = event => {
    this.setState({ email:event.target.value })
  }
  
render() {  
    const result = this.state.email.toString()
    const hp = result.replace('\\left[\\begin{array}{cc}', '')
    const hp2 =hp.replace('\\left[\\begin{array}{ccc}', '')
    const hp3 = hp2.replace('\\left[\\begin{array}{cccc}', '')
    const hp4 = hp3.replace('\\left[\\begin{array}{ccccc}', '')
    const hp5 = hp4.replace('\\left[\\begin{array}{cccccc}', '')
    const hp6 = hp5.replace('\\left[\\begin{array}{ccccccc}', '')
    const hp7 = hp6.replace('\\left[\\begin{array}{cccccccc}', '')
    const hpp83 = hp7.replace('\\end{array}\\right]', '')
    const hpp831 = hpp83.replace('\\begin{equation}', '')
    const hpp832 = hpp831.replace('\\end{equation}', '')

  return (
      
    <>
    
    <CardTitle className="text-center  mb-3">
   <a><h1> Matrices</h1></a>
  </CardTitle>
 
 
  <br />
      <Form className="text-center" onSubmit={this._handleSubmit}>
        <FormGroup>
        <Input
         
          label="Email"
          autoComplete="email"
          variant="outlined"
          value={this.state.email}
          onChange={this.handleChange} 
        />
        <br />
        <BlockMath math= { `\\mathrm{A} = \\left[\\begin{array}{lll} ${hpp832} \\end{array}\\right] ` }
        errorColor={'#cc0000'}
        renderError={(error) => {
            return <b>Error: That's not math bruh</b>
          }}
        
        />

        <motion.div
      
      whileHover={{ scale: 1.1,  }}
      whileTap={{ scale: 0.9,  }}
   
  >
      <br />
       < button  className="btn btn-outline-success text-uppercase">
         Calcular
          </button>
          </motion.div>
          <br />

          < h1> {this.state.message} </h1>
          <br />
          <br />

          <BlockMath math= { ` \\mathrm{A^{-1}} = \\left[\\begin{array}{lll} ${this.state.inv} {}\\end{array}\\right] `}
          
          />
          <BlockMath math= { '\\LaTeX :' }/>
<p>
{`\\begin{equation} \\left[\\begin{array}{ccc} ${this.state.inv} {}\\end{array}\\right] \\end{equation}`}
</p> 

<br />
          
 <BlockMath math= { ` \\mathrm{adj(A)} =  \\left[\\begin{array}{lll} ${this.state.adj} {}\\end{array}\\right] `}
          
          />
<BlockMath math= { '\\LaTeX :' }/>
<p>
{`\\begin{equation} \\left[\\begin{array}{ccc} ${this.state.adj} {}\\end{array}\\right] \\end{equation}`}
</p>

<BlockMath math= { `\\mathrm{det(A)}= ${this.state.det}  `}

          
          />

<br />



      


    
        </FormGroup>
      </Form>
      </>
    )
  }
}